/**
 * Since this uses Grunt, the function "import" can't be used out of the box.
 * If you're adding third party script libraries (as in npm packages),
 * include them in Gruntfile.js's concat section.
 * 
 * Third party npm libraries included in Gruntfile.js:
 * - flexslider
 */

var main = (function ($) {
	function initFrontpageHeroSlider() {
		const frontpageHeroSliderEl = $('.hero__bg-section.slider');
		
		// If the page doesn't have the front page hero element with slider, bail out
		if (!frontpageHeroSliderEl) return;

		$(window).load(function () {
			$('.hero__bg-section.slider').flexslider({
				animation: 'slide',
				slideshowSpeed: 4000,
				manualControls: '.hero__slider-controllers .pagination li',
			});
		});
	}

	function init() {
		$('.flexslider').flexslider();
	
		$('.js-toggle-site-nav').on("click", function (event) {
			event.preventDefault();
			$(this).toggleClass('open');
			$('.main-menu-area').toggle();
		});

		initFrontpageHeroSlider();
	
	}

	return {
		init: init
	};
}(jQuery));

